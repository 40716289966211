"use client";
import { useEffect, useState, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from "@emotion/react";
import createCache from '@emotion/cache';
import { store } from "./redux/store/StoreProvider";

const ThemeWrapper = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(store.getState().language.language);
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const { language } = store.getState();
      if (currentLanguage !== language.language) {
        setCurrentLanguage(language.language || '');
      }
    });

    const createDynamicTheme = () => {
      const root = document.querySelector(":root");
      const observer = new MutationObserver(() => {
        createThemeFromCSSVariables();
      });

      const observerConfig = {
        attributes: true,
        attributeFilter: ["style"],
        subtree: true,
      };

      observer.observe(root, observerConfig);
      createThemeFromCSSVariables();

      return () => {
        observer.disconnect();
      };
    };

    const createThemeFromCSSVariables = () => {
      const root = document.querySelector(":root");
      const mainColor = getComputedStyle(root).getPropertyValue("--main-color").trim();
      const secondaryColor = getComputedStyle(root).getPropertyValue("--secondary-color");
      const backgroundColor = getComputedStyle(root).getPropertyValue("--background-color");
      const textColorOverBackgroundColor = getComputedStyle(root).getPropertyValue("--text-color-over-background-color");
      const textColorOverSecondaryColor = getComputedStyle(root).getPropertyValue("--text-color-over-secondary-color");
      const lightTextColorOverSecondaryColor = getComputedStyle(root).getPropertyValue("--light-text-color-over-secondary-color");
      const mainFont = getComputedStyle(root).getPropertyValue("--main-font");
      const regularFont = getComputedStyle(root).getPropertyValue("--font-regular");
      const errorColor = getComputedStyle(root).getPropertyValue("--input-border-error-color").trim();

      const newTheme = createTheme({
        direction: currentLanguage === "arabic" ? "rtl" : "ltr",
        typography: {
          fontFamily: mainFont,
        },
        palette: {
          primary: {
            main: mainColor,
          },
          text: {
            secondary: textColorOverBackgroundColor,
            primary: textColorOverSecondaryColor,
          },
          background: {
            default: backgroundColor,
            paper: secondaryColor,
          },
          divider: "rgba(145, 158, 171, 0.24)",
          box: "#b975e0",
          error: {
            main: errorColor,
          },
        },
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                backgroundColor: `${secondaryColor}`,
                color: textColorOverSecondaryColor,
                borderRadius: "12px",
              },
            },
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                font: "var(--font-regular)",
                fontSize: "var(--font-eleven)"
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              icon: {
                color: textColorOverSecondaryColor,
              },
              '.MuiSelect-iconOpen': {
                color: textColorOverSecondaryColor,
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                font: regularFont, 
              },
            },
          },         
          MuiInputLabel: {
            styleOverrides: {
              root: {
                fontFamily: mainFont === "Segoe UI" ? "Segoe UI Italic" : mainFont,
                fontSize: "var(--font-fourteen)",
                fontStyle: mainFont === "Segoe UI" ? "normal" : "italic",
                color: "var(--light-text-color-over-secondary-color)"
              },
            },
          },
          //level stepper styles
          MuiStepIcon: {
            styleOverrides: {
              root: {
                width: '1.7rem',
                height: '1.7rem',
                color: 'var(--disabled-color)',
                '&.Mui-completed': {
                  color: 'var(--main-color)',
                },
                '&.Mui-active': {
                  color: 'var(--disabled-color)',
                },
                '& .MuiStepIcon-text': {
                  fill: 'transparent',
                },
              },
            },
          },
          MuiStepLabel: {
            styleOverrides: {
              label: {
                '&.Mui-completed.MuiStepLabel-alternativeLabel': {
                  color: 'grey.500', // Text color for completed steps
                },
                '&.Mui-active.MuiStepLabel-alternativeLabel': {
                  color: 'var(--main-color)', // Text color for active steps
                },
              },
              labelContainer: {
                '& .MuiStepLabel-label': {
                  '&.Mui-completed': {
                    color: 'var(--main-color)',
                    // Text color for completed steps
                  },
                  '&.Mui-active': {
                    color: 'var(--main-color)', // Text color for active steps
                  },
                  '&:not(.Mui-completed):not(.Mui-active)': {
                    color: 'var(--disabled-color)', // Default text color for not completed and not active steps
                  },
                },
              },
            },
          },
          MuiStepConnector: {
            styleOverrides: {
              lineHorizontal: {
                borderTopWidth: '3.7px',
                borderColor: 'var(--disabled-color)', // Default color for non-completed steps
              },
              lineVertical: {
                borderLeftWidth: '3.7px',
                borderColor: 'var(--disabled-color)', // Default color for non-completed steps
              },
              root: {
                '&.MuiStepConnector-alternativeLabel': {
                  paddingLeft: '8px', // Adjust spacing for alternative label
                },
                '&.Mui-completed .MuiStepConnector-line': {
                  borderColor: 'var(--main-color)', // Color for completed steps
                },
              },
            },
          },
        },
      });

      setTheme(newTheme);
    };

    createDynamicTheme();

    return () => {
      unsubscribe();
    };
  }, [currentLanguage]);

  const cache = useMemo(() => {
    return createCache({
      key: currentLanguage === "arabic" ? "rtl" : "ltr",
      stylisPlugins: currentLanguage === "arabic" ? [rtlPlugin] : [],
    });
  }, [currentLanguage]);

  if (!theme) {
    return null;
  }

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
  );
};

export default ThemeWrapper;
